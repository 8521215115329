.pending-claim-box {
  background-color: #fff0eb;
  border-radius: 0;
  border-left: 5px solid#f07400;
  margin-top: 20px;
  .text-color {
    color: #e48201;
  }
}

.success-claim-box {
  background-color: #f6fcfa;
  border-radius: 0;
  border-left: 5px solid#32bb00;
  margin-top: 20px;
  .text-color {
    color: #32bb00;
  }
}

.reject-claim-box {
  background-color: #f6fcfa;
  border-radius: 0;
  border-left: 5px solid red;
  .text-color {
    color: red;
  }
}
