.flag-wrap {
    width: 48px;
    min-height: 48px;
    justify-content:center
}

.f-14{
    font-size: 10px;
}

.f-18{
    font-size: 18px;
}

.rounded-6_i{
    border-radius: 0.6rem;
}

.rounded-card{
    border-radius: 1rem;
}

.text-danger_i{
    color: #f10000;
}

.text-info_i{
    color: #105099;
}

.bg-info_i{
    background-color: #105099;
}

::placeholder{
    color: black;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: black;
   }
   
   ::-ms-input-placeholder { /* Microsoft Edge */
    color: black;
   }