.verticalTab li .active {
  background: #ddf3ff;
}
.verticalTab .nav-item {
  border-bottom: 1px solid #efefef;
}

.verticalTab li {
  cursor: pointer;
}

.font-14 {
  font-size: 14px;
}
