.row-round{
    border-radius: 20px;
}

.headings{
    font-size: 1.2rem;
}

.padding-four{
    padding: 2rem 1.5rem;
}

.padding-four-demo{
    padding: 0.8rem;
}

.width-75{
    width: 70px;
}

.margin-first{
    /* margin: 2.5rem 4rem 5rem; */
    margin: 1rem 4rem;
}

.round-less{
    border-radius: 10px;
}

.bg-icon{
    background-color: #e8edf2;
    border-radius: 20px;
}

.bg-bank{
    background-color: #f7f7f7;
    border-radius: 15px;
}

.bank-active{
    border: 2px solid #c7e9f5;
}

.bank-active span{
    top: 3%;
    right: 5%;
}

.bank-active span img{
    width: 20px;
    height: 20px;
}

.bg-bank img{
    width: 80px;
    height: 80px;
}

.dashed-border{
    border: 2px dashed #e0e0e5;
    border-radius: 15px;
}

.tick-img{
    width: 60px;
}

.bank-img{
    width: 100px;
    height: 100px;
}

.bank-capital{
    width: 170px;
}

.btn-view{
    background-color: #f1f1f1;
}

.green-text{
    color: #6cdb32;
}

.right-dashed{
    border-right: 2px dashed #cbcbcb;
}

.bg-account{
    background-color: #eff7fe;
}

.text-header-gray{
    color: #b7b7c3;
}

.negative-margin{
    margin-top: -4rem;
}