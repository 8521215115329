.tbl-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  flex-wrap: wrap;
  padding: 5px 0;
  margin-right: 15px;
  .paging {
    display: flex;
    align-items: center;
  }
  .info {
    color: #777;
  }
  select {
    margin-right: 10px;
    flex: 0 0 70px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    li {
      &.active {
        background: #cecece;
      }
      &:last-of-type {
        margin-right: 0;
      }
      cursor: pointer;
      padding: 5px;
      background: #eee;
      margin-right: 5px;
      min-width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
    }
  }
}
