#exTab2 ul li{
    padding: 2px 15px 0px 0px;
    cursor: pointer;
}
#exTab2 ul li a{
    text-decoration: none;
}
#extab2 .nav-item .active{
    border-bottom: 2px solid #4b5157;
    color: #113459;
}
.nav-link{
    padding: 0.2rem 1rem;
}