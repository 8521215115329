.wrap-file-upload {
  width: 80%;
  border-radius: 1rem;
  border: 2px dashed #d0d0db;
  p {
    margin: 0;
    color: #5c6874;
    font-size: 1.15rem;
    &:first-child {
      margin: 0.75rem 0 0 0;
    }
    &:last-child {
      color: #1d61a4;
    }
  }
}
.bg-gray {
  background-color: #ececec;
}
.bg-limegreen {
  background: #dcffe8;
}
.bg-green {
  background: #f5fff8;
}
.bg-lightblue {
  background: #dcf6ff;
}
.bg-purple {
  background: #ebcfee;
}
.bg-lightred {
  background: #fdebd9;
}
.bg-semiblue {
  background-color: #88d0eb;
}
.bg-lightorange {
  background: #fffbfb;
}
.bg-lightpink {
  background: #fef6ff;
}
.rounded-10 {
  border-radius: 10px;
}
.dropdown-menu {
  min-width: 15rem !important;
}
.f-13 {
  
    font-size: 11px;
    color: grey;
    padding-top: 3px;
}

.f-15 {
  font-size: 1rem;
}

.f-9 {
  font-size: 0.9rem;
}

.f-8 {
  font-size: 0.8rem;
}

.bg-lightgreen_i {
  background-color: #dbfee7;
}

.bg-darkgreen_i {
  background-color: #dcffe8;
}

.bg-lightblue_i {
  background-color: #ddf3ff;
}

.bg-darkblue_i {
  background-color: #dcf6ff;
}

.bg-lightpurple_i {
  background-color: #ecd0ef;
}

.bg-darkpurple_i {
  background-color: #ebcfee;
}

.bg-peach_i {
  background-color: #ffeeee;
}

.bg-lightochre_i {
  background-color: #fdebd9;
}

.bg-lightpink_i {
  background-color: #ffe6ec;
}

.bg-brown_i {
  background-color: #a56d7a;
}

.bg-voilet_i {
  background-color: #945ca0;
}

.bg-ochre_i {
  background-color: #e29546;
}

.bg-green_i {
  background-color: #70a482;
}

.flexi-shadow_i {
  box-shadow: 2px 2px 2px #e7e5e5;
}
.w-60 {
  width: 60%;
}
.w-85 {
  width: 85%;
}
.nav-item .active {
  color: #113459;
  border-bottom: 4px solid #113459;
}

.modal-header {
  border-bottom: none !important;
}

.rounded-6_i {
  border-radius: 0.6rem;
}

.modal-footer {
  border-top: none !important;
}

.btn-popup {
  background-image: linear-gradient(45deg, #8cd4ed, #105099);
  border: none;
  border-radius: 10px;
  /* padding: 0.8rem 2rem; */
  /* min-width: 15rem; */
  font-size: 14px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
}

.btn-disabled {
  font-size: 0.9rem;
  background-color: lightgrey;
}

.font-12 {
  font-size: 12px;
}
.w-16 {
  width: 16%;
}
.w-12 {
  width: 12%;
}
.bg-lightgreen {
  background: #23d510;
}
button:disabled {
  cursor: no-drop;
}
.files-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
}

.prev-pagination,
.next-pagination {
  cursor: pointer;
  &.disabled {
    color: #b5b5b5;
  }
}

.error-row {
  background: #ffdde5;
}
.error-column{
  background: #ffb4c5;
}

.align-table-data-left{
  text-align: left;
}