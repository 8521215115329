.f-14{
    font-size: 14px;
}

.f-18{
    font-size: 18px;
}

.rounded-6_i{
    border-radius: 0.6rem;
}

.rounded-card{
    border-radius: 1rem;
}

.text-danger_i{
    color: #f10000;
}

.text-info_i{
    color: #105099;
}

.bg-info_i{
    background-color: #105099;
}

.text-justify{
    text-align: justify;
}

.disclaimer-scroll{
    max-height: 65vh; 
    overflow-y: auto;
}

.disclaimer-scroll::-webkit-scrollbar{
    width: 8px;
}

.disclaimer-scroll::-webkit-scrollbar-track{
    background-color: #f1f1f1;
}

.disclaimer-scroll::-webkit-scrollbar-thumb{
    background-color: #888;
    border-radius: 5px;
}

.disclaimer-scroll::-webkit-scrollbar-thumb:hover{
    background-color: #555;
}