
.icon-box {
    width: 40px;
    height: 40px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#fff;
}

.progress-line{
    position: absolute;
    height: 3px;
    top: 18px;
    left: calc(-50% + 5px);
    right: calc(50% + 5px);
    background: #ddd;
   
}

.bg-backstep{
    background-color: lightgray;
}


