.custom-tooltip{
    border: 1px solid #cdd2d7;
    background:white;
    display:flex;
    flex-direction: column;
    padding: 0.8em 0.6em 1em 0.6em;
}
.tooltip-label1{
 font-size:1em;
}
.tooltip-label2{
    font-size: 0.9em;
    margin-top: 0.3em;
    opacity:0.8;
    color: red;
}