#search-box {
  width: 100%;
  height: 3rem;
  display: flex;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  background: #fff;
  border: 1px solid #eee;
  .btn-container {
    height: 100%;
    display: flex;
    align-items: center;
    button {
      height: 36px;
      width: 105px;
      margin-right: 5px;
    }
  }

  input[type="search"] {
    -webkit-appearance: textfield;
    width: 100%;
    padding: 0.65rem;
    border: none;
    border-radius: 0.5rem;
    &:focus {
      outline: none;
      outline-offset: none;
    }
  }
}
