.page-heading {
  .title {
    color: rgb(134, 134, 134);
    font-weight: bold;
  }
  a {
    text-decoration: none;
  }
  a:hover {
    color: #000;
  }

  color: rgb(134, 134, 134);
  font-weight: bold;
}

.color0 {
  // color: #c6f1ff !important;
  &.white-card {
    background: #fff;
  }
  &.active {
    border: 1px solid #000;
  }
}

.color1 {
  // color: #c6f1ff !important;
  &.white-card {
    background: #c6f1ff;
  }
  &.active {
    border: 1px solid #000;
  }
}
.color2 {
  // color: #fffee9 !important;
  &.white-card {
    background: #fffee9;
  }
  &.active {
    border: 1px solid #000;
  }
}
.color3 {
  // color: #88d0eb !important;
  &.white-card {
    background: #88d0eb;
  }
  &.active {
    border: 1px solid #000;
  }
}
.color4 {
  // color: #dcf6ff !important;
  &.white-card {
    background: #dcf6ff;
  }
  &.active {
    border: 1px solid #000;
  }
}
.color5 {
  // color: #ebcfee !important;
  &.white-card {
    background: #ebcfee;
  }
  &.active {
    border: 1px solid #000;
  }
}
.color6 {
  // color: #ffeddb !important;
  &.white-card {
    background: #ffeddb;
    &.status-dot-card {
      background: #fff;
    }
  }
  &.active {
    border: 1px solid #000;
  }
}
.color7 {
  // color: #dcffe8 !important;
  &.white-card {
    background: #dcffe8;
  }
  &.active {
    border: 1px solid #000;
  }
}
.color8 {
  // color: #f98464 !important;
  &.white-card {
    background: #f98464;
  }
  &.active {
    border: 1px solid #000;
  }
}
.color9 {
  // color: #ececec !important;
  &.white-card {
    background: #ececec;
  }
  &.active {
    border: 1px solid #000;
  }
}
.color10 {
  // color: #694ff8 !important;
  &.white-card {
    background: #694ff8;
  }
  &.active {
    border: 1px solid #000;
  }
}
.color11 {
  // color: #ff6d6d !important;
  &.white-card {
    background: #ff6d6d;
  }
  &.active {
    border: 1px solid #000;
  }
}

.color12 {
  // color: #c6f1ff !important;
  &.white-card {
    background: #c6f1ff;
  }
  &.active {
    border: 1px solid #000;
  }
}

.color13 {
  // color: #c6f1ff !important;
  &.white-card {
    background: #c6f1ff;
  }
  &.active {
    border: 1px solid #000;
  }
}

.color14 {
  // color: #c6f1ff !important;
  &.white-card {
    background: #c6f1ff;
  }
  &.active {
    border: 1px solid #000;
  }
}

.color15 {
  // color: #c6f1ff !important;
  &.white-card {
    background: #f53332;
  }
  &.active {
    border: 1px solid #000;
  }
}

.status-dot {
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  right: 10px;
}
.status-dot-card {
  &.color0 .status-dot {
    background: #fff;
    color: #000;
  }
  &.color1 .status-dot {
    background: #85ddfa;
    color: #000;
  }
  &.color2 .status-dot {
    background: #f48fb1;
    color: #000;
  }
  &.color3 .status-dot {
    background: #43beeb;
    color: #fff;
  }
  &.color4 .status-dot {
    background: #7ab0c5;
    color: #fff;
  }
  &.color5 .status-dot {
    background: #e78bf1;
    color: #fff;
  }
  &.color6 .status-dot {
    background: #fab36d;
    color: #000;
  }
  &.color7 .status-dot {
    background: #62f595;
    color: #000;
  }
  &.color8 .status-dot {
    background: #f5704c;
    color: #fff;
  }
  &.color9 .status-dot {
    background: #646464;
    color: #fff;
  }
  &.color10 .status-dot {
    background: #694ff8;
    color: #fff;
  }
  &.color11 .status-dot {
    background: #ff6d6d;
    color: #fff;
  }
  &.color12 .status-dot {
    background: #62b685;
    color: #000;
  }
  &.color13 .status-dot {
    background: #cc13bd;
    color: #000;
  }
  &.color14 .status-dot {
    background: #f56262;
    color: #000;
  }
  &.color15 .status-dot {
    background: #f53332;
    color: #000;
  }
}

.white-card {
  &.status-dot-card {
    position: relative;
    background: #fff;
    &.bg-gray {
      background-color: #ececec !important;
    }
  }
}
