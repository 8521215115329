.white-cardmember {
    border-radius: 0.75rem;
    margin-bottom: 1rem;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 0.7rem;
    background: #fff;
    width: 169px;
    // border: 1px;
    border:solid;
    border-width: 1px;
}