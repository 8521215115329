#sub-header {
    .wrap-profile {
        width: 3rem;
        height: 3rem;
    }
}

.wallet-balance{
    display:flex;
    flex-wrap:wrap;
    justify-content: space-around;
}

