.count-boxes {
  grid-template-columns: repeat(auto-fill, minmax(calc(17% - 10px), 1fr));
  display: grid;
  .count-box {
    padding: 20px;
    margin: 10px;
    text-align: center;
    &:nth-child(1) {
      background: #f5fff8;
    }
    &:nth-child(2) {
      background: #f7fdff;
    }
    &:nth-child(3) {
      background: #fef6ff;
    }
    &:nth-child(4) {
      background: #f2fff1;
    }
    &:nth-child(5) {
      background: #fbfcff;
    }
    &:nth-child(6) {
      background: #fffbfb;
    }
  }
}


